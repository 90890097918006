.hero {
    gap: 0 90px;
    padding-top: 50px;
}

.hero-content {
    width: 100%;
    max-width: 530px;
    padding-bottom: 80px;
}

.hero-heading {
    width: 100%;
    height: 50%;
    font-family: 'Frank Ruhl Libre';
    font-style: normal;
    font-weight: 700;
    font-size: 78px;
    line-height: 78px;
    margin-bottom: 30px;
    text-transform: capitalize;
    color: var(--brown-color);
}

.hero-desc {    
    font-family: 'Lato', sans-serif;
    width: 87.17%;
    height: 120px;
    font-weight: 400;
    font-size: 22px;
    line-height: 180%;
    text-transform: capitalize;
    margin-bottom: 30px;
    color: var(--brown-color);
}

.button--shop {
    font-family: 'Lato', sans-serif;
    display: flex;
    align-items: center;
    line-height: 1;
    font-size: 16px;
    cursor: pointer;
    border: none;
    outline: none;
    font-weight: 900;
    background-color: var(--brown-color);
    justify-content: center;
    color: white;
    width: 223px;
    padding: 16px 61px;
}

img[alt="heroImage"] {
    width: 45.478%;
    height: 650px;
    border-bottom-left-radius: 25%;
    margin-bottom: 112px;
    z-index: 100;
}

.rectancle-1 {
    position: absolute;
    width: 26.953%;
    height: 249px;
    background: linear-gradient(360deg, #2C5FBA -21.17%, rgba(211, 161, 126, 0) 115.88%);
    opacity: 0.34;
    bottom: 0px;
    left: 0px;
    z-index: 1;
}

.rectancle-2 {
    position: absolute;
    width: 45.654%;
    height: 529px;
    background: linear-gradient(360deg, #2C5FBA -21.17%, rgba(211, 161, 126, 0) 115.88%);
    opacity: 0.34;
    top: 0;
    right: 0;
    z-index: 1;
}
