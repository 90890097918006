.ourproduct-container {
	flex-direction: column;
	gap: 40px 0px;
	position: relative;
}

.category-container {
	gap: 0 46px;
	list-style: none;
	margin: 0 0 30px 0;
	padding-left: 0;
	position: relative;
}

.ourpoduct-heading {
	font-family: 'Frank Ruhl Libre';
	font-style: normal;
	font-weight: 700;
	font-size: 48px;
	line-height: 62px;
	text-align: center;
	text-transform: capitalize;
	margin: 0 0 0 0;
	color: var(--brown-color),
}

.ourproduct-category-item p {
	font-family: 'Lato', sans-serif;
	font-weight: 400;
	font-size: 15px;
	line-height: 18px;
	letter-spacing: 0.115em;
	text-transform: uppercase;
	cursor: pointer;
    display: flex;
    justify-content: space-around;
    align-items: center;
	color: var(--brown-color),
}


.grid-container {
	display: grid;
	grid-template-columns: auto auto auto auto;
	gap: 40px 24.98px;
}

.cart .product-card {
	min-width: 265.77px;
	height: 388px;
	border: 1px solid black;
}

img[alt='ourproduct-img'] {
	min-width: 265.77px;
	height: 285px;
}

.card-content-container--outproduct {
	display: flex;
	gap: 10px 0px;
	align-items: center;
	padding-left: 0px;
}

.price-container--ourproduct {
	align-items: center;
	gap: 0px 7px;
}

.decorate-3 {
	position: absolute;
	width: 500px;
	height: 500px;
	background: linear-gradient(
		360deg,
		rgba(211, 161, 126, 0) -21.17%,
		#005afc 60.63%
	);
	opacity: 0.23;
	filter: blur(134.5px);
	left: -35%;
	top: -25%;
	z-index: 1;
}

.decorate-4 {
	position: absolute;
	width: 500px;
	height: 500px;
	background: linear-gradient(
		360deg,
		rgba(211, 161, 126, 0) -21.17%,
		#005afc 60.63%
	);
	opacity: 0.23;
	filter: blur(134.5px);
	right: -65%;
	bottom: -25%;
	z-index: 1;
}

.category-container input {
	display: none;
}

.category-container .ourproduct-nav-tab {
	position: absolute;
	height: 100%;
	width: 20%;
	left: 0;
	bottom: -3px;
	z-index: 0;
	transition: 0.6s ease-in-out;
    border-bottom: 2px solid;
}


.category-container #ourproduct-hot:checked ~ .ourproduct-nav-tab {
    left: -1%;
    width: 12%;
}
.category-container #ourproduct-sale:checked ~ .ourproduct-nav-tab {
    left: 26%;
    width: 22%;
}
.category-container #ourproduct-new:checked ~ .ourproduct-nav-tab {
    left: 63%;
    width: 37%;
}