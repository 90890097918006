@import url('https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap');

.wrapper{
  height: 60px;
  width: 100% ;
  background: #fff;
  line-height: 60px;
  border-radius: 10px;
  box-shadow: 0 5px 10px rgba(0,0,0,0.25);
}
.wrapper nav{
  position: relative;
  display: flex;
}
.wrapper nav label{
  flex: 1;
  width: 100%;
  z-index: 1;
  cursor: pointer;
  display: flex;
  justify-content: space-evenly;
}
.wrapper nav label p{
  position: relative;
  z-index: -1;
  color: #1d1f20;
  font-size: 15px;
  font-weight: 500;
  text-decoration: none;
  transition: color 0.6s ease;
  font-family: 'Poppins', sans-serif;
}
.wrapper nav #history-all:checked ~ label.history-all p,
.wrapper nav #history-cart:checked ~ label.history-cart p,
.wrapper nav #history-delivering:checked ~ label.history-delivering p,
.wrapper nav #history-delivered:checked ~ label.history-delivered p,
.wrapper nav #history-cancelled:checked ~ label.history-cancelled p{
  color: #fff;
}

.wrapper nav .tab{
  position: absolute;
  height: 100%;
  width: 20%;
  left: 0;
  bottom: 0;
  z-index: 0;
  border-radius: 10px;
  background: linear-gradient(45deg, #05abe0 0%,#001e54 100%);
  transition: 0.6s ease-in-out;
}
.wrapper nav #history-cart:checked ~ .tab{
  left: 20%;
}
.wrapper nav #history-delivering:checked ~ .tab{
  left: 40%;
}
.wrapper nav #history-delivered:checked ~ .tab{
  left: 60%;
}
.wrapper nav #history-cancelled:checked ~ .tab{
  left: 80%;
}
.wrapper nav input{
  display: none;
}