.button-cart,
.button-cart::after {
    box-sizing: border-box;
    position: relative;
    display: block;
    width: 20px;
    height: 20px;
    border-radius: 3px
}

.button-cart {
    z-index: 3;
}

.number-item-cart {
    position: absolute;
    width: 50%;
    height: 50%;
    right: 0px;
    top: 0px;
    z-index: 4;
    color: white;
    background-color: red;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 70%;
}