@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&family=Quicksand:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400);
@import url(https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap);
:root {
	--white-color: #fff;
	--black-color: #000;
	--text-color: #1d1f20;
	--lighblue-color: #e0f6f8;
	--heavyblue-color: #001e54;
	--gray-color: #f3f3f3;
	--bluetext-color: #2361ff;
	--red-color: #C20A0A;
	--brown-color: #34251F;
	
}

* {
	box-sizing: inherit; /*Kế thừa*/
	margin: 0 0;
	padding: 0 0;
	font-family: 'Lato', sans-serif;
}

html {
	font-family: 'Lato', sans-serif;
	font-style: normal;
	color: #1d1f20;
	color: var(--text-color);
	box-sizing: border-box;
}

body {
	margin: 0;
	overflow-y: scroll;
	overflow-x: hidden;
}

.App {
	max-width: 100%;
	margin: 0 auto;
}
.container-item {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

a {
	text-decoration: none;
}

img {
	display: block;
	max-width: 100%;
}

.button {
	display: inline-block;
	align-items: center;
	font-family: 'Lato', sans-serif;
	line-height: 1;
	font-size: 16px;
	cursor: pointer;
}

.line {
	border-left: 0.5px solid black;
	height: 56px;
	left: 50%;
	margin-left: -3px;
	top: 0;
}

.container {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.container-1139 {
	display: flex;
	justify-content: space-between;
	align-items: center;
	max-width: 1139px;
	margin: 0 auto;
}

.container-1056 {
	display: flex;
	justify-content: space-between;
	align-items: center;
	max-width: 1056px;
	margin: 0 auto;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.landing-page-header {
    background-color: var(--heavyblue-color);
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
}

.menu {
    display: flex;
    align-items: center;
    list-style: none;
    grid-gap: 0 20px;
    gap: 0 20px;
}

.header-logo {
    font-family: 'Lato', sans-serif;
    font-weight: 900;
    font-size: 25px;
    line-height: 30px;
    text-align: left;
    -webkit-padding-start: 0px;
            padding-inline-start: 0px;
    color: white;
}

.menu-link {
    font-family: 'Lato', sans-serif;
    font-weight: 900;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.115em;
    color: var(--text-color);
    text-transform: uppercase;
    color: white;
}

img[alt='landingpage-avatar'] {
    width: 27px;
    height: 35px;
    border-radius: 50%;
    padding: 5px 0;
}

.menu-link:hover {
    text-decoration: underline;
}

.header-auth {
    display: flex;
    align-items: center;
    grid-gap: 0 20px;
    gap: 0 20px;
}

body {
    background-color: white;
}

.button-cart {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform: scale(var(--ggs, 1));
            transform: scale(var(--ggs, 1))
}

.button-login {
    font-weight: 700;
    color: white;
    padding: 14px 30.5px;
}
.button-cart,
.button-cart::after {
    box-sizing: border-box;
    position: relative;
    display: block;
    width: 20px;
    height: 20px;
    border-radius: 3px
}

.button-cart {
    z-index: 3;
}

.number-item-cart {
    position: absolute;
    width: 50%;
    height: 50%;
    right: 0px;
    top: 0px;
    z-index: 4;
    color: white;
    background-color: red;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 70%;
}
.vecto {
    position: absolute;
    display: grid;
    grid-template-columns: auto auto auto auto auto auto;
    width: 131.5px;
    height: 107px;
    z-index: 2;
}

.vecto-item {
    margin: 0 0 0 0;
    font-weight: 900;
    font-size: 20px;
}

.vecto-1 {
    top: 12%;
}

.vecto-2 {
    right: 42%;
    bottom: 12%;
}

.vecto-3 {
    top: 25%;
    right: 3%;
}
.hero {
    grid-gap: 0 90px;
    gap: 0 90px;
    padding-top: 50px;
}

.hero-content {
    width: 100%;
    max-width: 530px;
    padding-bottom: 80px;
}

.hero-heading {
    width: 100%;
    height: 50%;
    font-family: 'Frank Ruhl Libre';
    font-style: normal;
    font-weight: 700;
    font-size: 78px;
    line-height: 78px;
    margin-bottom: 30px;
    text-transform: capitalize;
    color: var(--brown-color);
}

.hero-desc {    
    font-family: 'Lato', sans-serif;
    width: 87.17%;
    height: 120px;
    font-weight: 400;
    font-size: 22px;
    line-height: 180%;
    text-transform: capitalize;
    margin-bottom: 30px;
    color: var(--brown-color);
}

.button--shop {
    font-family: 'Lato', sans-serif;
    display: flex;
    align-items: center;
    line-height: 1;
    font-size: 16px;
    cursor: pointer;
    border: none;
    outline: none;
    font-weight: 900;
    background-color: var(--brown-color);
    justify-content: center;
    color: white;
    width: 223px;
    padding: 16px 61px;
}

img[alt="heroImage"] {
    width: 45.478%;
    height: 650px;
    border-bottom-left-radius: 25%;
    margin-bottom: 112px;
    z-index: 100;
}

.rectancle-1 {
    position: absolute;
    width: 26.953%;
    height: 249px;
    background: linear-gradient(360deg, #2C5FBA -21.17%, rgba(211, 161, 126, 0) 115.88%);
    opacity: 0.34;
    bottom: 0px;
    left: 0px;
    z-index: 1;
}

.rectancle-2 {
    position: absolute;
    width: 45.654%;
    height: 529px;
    background: linear-gradient(360deg, #2C5FBA -21.17%, rgba(211, 161, 126, 0) 115.88%);
    opacity: 0.34;
    top: 0;
    right: 0;
    z-index: 1;
}

.newcollection-container {
    flex-direction: column;
    grid-gap: 24px 0;
    gap: 24px 0;
    position: relative;
}

.heading {
    font-family: 'Frank Ruhl Libre';
    font-style: normal;
    font-weight: 700;
    font-size: 68px;
    padding-top: 100px;
    color: var(--brown-color);
}

.desc {
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-size: 20px;
    color: var(--brown-color);
}

.collection-list {
    grid-gap: 0 24px;
    gap: 0 24px
}

.collection-item {
    position: relative;
    text-align: center;
}

img[alt="listItemImage"] {
    height: 350px;
}

.collection-listitem-desc {
    display: inline-block;
    align-items: center;
    padding: 7px 70px;
    background-color: var(--text-color);
    color: white;
    font-weight: 900;
    font-size: 15px;
    line-height: 22px;
    text-transform: uppercase;
    position: absolute;
    top: 92.5%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    white-space: nowrap; /*Không xuống hàng*/
    font-family: 'Lato', sans-serif;
}

.decorate-1 {
    position: absolute;
    box-sizing: border-box;
    width: 608px;
    height: 608px;
    background: linear-gradient(360deg, rgba(211, 161, 126, 0) -21.17%, #005AFC 89.9%);
    opacity: 0.3;
    border: 1px solid #E0F6F8;
    -webkit-filter: blur(134.5px);
            filter: blur(134.5px);
    left: -700px;
    z-index: 1;
}
.aboutus-container {
    position: relative;
    grid-gap: 0 90px;
    gap: 0 90px;
    height: auto;
    align-items: flex-start;
    padding-top: 220px;
    position: relative;
}

.aboutus-img-container {
    width: 200%;
    height: 662;
}


img[alt='aboutus-img'] {
    width: 175%;
    height: 662px;
    border-top-left-radius: 25%;
}

.aboutus-infor {
    display: flex;
    flex-direction: column;
    grid-gap: 35px 0;
    gap: 35px 0;
    padding-top: 40px;
}

.aboutus-heading {
    font-family: 'Frank Ruhl Libre';
    font-style: normal;
    font-weight: 700;
    font-size: 68px;
    line-height: 78px;
    margin: 0 0 0 0;
    color: var(--brown-color);
    
}

.aboutus-bdesc {
    font-weight: 400;
    font-size: 20px;
    line-height: 180%;
    margin: 0 0 0 0;
    font-family: 'Lato', sans-serif;
    color: var(--brown-color);
}

.aboutus-list-container {
    position: absolute;
    width: 755px;
    height: 173px;
    background-color: whitesmoke;
    padding-left: 50px;
    padding-right: 50px;
    bottom: 0px;
    right: 0px;
}


.bestseller {
    margin-top: 157px;
    margin-bottom: 157px;
}

.bestseller {
    background-color: var(--heavyblue-color);
}

.bestseller-wrapper{
    max-width: 1440px;
    margin: 0 auto;
}

.bestseller-container {
    max-width: 1139px;
    margin: 0 auto;
}

.bestseller-content-container {
    min-width: 337px;
    flex-direction: column;
    grid-gap: 23px 0;
    gap: 23px 0;
    align-items: flex-start;
}

.bestseller-heading {
    font-weight: 700;
    font-size: 52px;
    line-height: 67px;
    color: var(--white-color);
    text-transform: capitalize;
    margin: 0 0 0 0;
}

.bestseller-desc {
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-size: 20px;
    line-height: 180%;
    color: var(--white-color);
    text-transform: capitalize;
    margin: 0 0 0 0;
}

.seemore-button {
    font-family: 'Lato', sans-serif;
    background-color: var(--heavyblue-color);
    color: white;
    border: 1px solid;
    margin-top: 22px;
    margin-bottom: 103px;
    padding: 13px 45px;
}

.scrollmenu {
    display: flex;
    grid-gap: 0 25px;
    gap: 0 25px;
    overflow-x: auto;
    width: 100%;
}

.scrollmenu::-webkit-scrollbar {
    width: 0;
}

.card {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    display: inline-block;
    min-width: 389px;
    height: 538px;
    margin: 80px 0 15px 0;
    background-color: white;
    border-radius: 6px;
}

img[alt="bestseller-img"] {
    height: 387px;
    width: 389px;
    border-bottom: 1px solid;
}

.card-item-name {
    display: flex;
    justify-content: center;
    font-family: 'Frank Ruhl Libre';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    margin: 0 0 0 0;
}

.price-container {
    grid-gap: 0 12px;
    gap: 0 12px;
    margin: 0 0;
}

.price {
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 180%;
    -webkit-text-decoration-line: line-through;
            text-decoration-line: line-through;
    margin: 0 0 0 0;
    color: #C20A0A;
    opacity: 0.2;
}

.discountprice {
    font-family: 'Lato', sans-serif;
    position: relative;
    font-weight: 700;
    font-size: 16px;
    line-height: 180%;
    margin: 0 0 0 0;
    color: #34251F;
    opacity: 0.9;
}

.card-content-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 39px;
    background-color: white;
    margin-top: 36px;
    margin-bottom: 29px;
    grid-gap: 10px 0;
    gap: 10px 0
}

.checked {
    color: orange;
}

.rec-slider-container {
    margin: 0 0 !important;
}

.rec-pagination {
    margin: 0 0 50px 0;
}

.sc-htoDjs {
    position: relative;
    bottom: 0px;
    padding-bottom: 60px ;
}

.sc-bxivhb {
    width: 12px;
    height: 12px;
}

.sc-EHOje {
    margin: 0 0;
}

.kVtMtA {
    left: -50px;
}

.bhRnqO {
    right: -50px;
}

.yuhsdf {
    display: flex;
    justify-content: space-between;
    grid-gap: 0 24px;
    gap: 0 24px;
}

.yuhsdf button {
    background-color: var(--heavyblue-color);
    color: white;
    font-size: 24px;
    border: none;
    padding: 4px 24px;
    cursor: pointer;
}
.ourproduct-container {
	flex-direction: column;
	grid-gap: 40px 0px;
	gap: 40px 0px;
	position: relative;
}

.category-container {
	grid-gap: 0 46px;
	gap: 0 46px;
	list-style: none;
	margin: 0 0 30px 0;
	padding-left: 0;
	position: relative;
}

.ourpoduct-heading {
	font-family: 'Frank Ruhl Libre';
	font-style: normal;
	font-weight: 700;
	font-size: 48px;
	line-height: 62px;
	text-align: center;
	text-transform: capitalize;
	margin: 0 0 0 0;
	color: var(--brown-color),
}

.ourproduct-category-item p {
	font-family: 'Lato', sans-serif;
	font-weight: 400;
	font-size: 15px;
	line-height: 18px;
	letter-spacing: 0.115em;
	text-transform: uppercase;
	cursor: pointer;
    display: flex;
    justify-content: space-around;
    align-items: center;
	color: var(--brown-color),
}


.grid-container {
	display: grid;
	grid-template-columns: auto auto auto auto;
	grid-gap: 40px 24.98px;
	gap: 40px 24.98px;
}

.cart .product-card {
	min-width: 265.77px;
	height: 388px;
	border: 1px solid black;
}

img[alt='ourproduct-img'] {
	min-width: 265.77px;
	height: 285px;
}

.card-content-container--outproduct {
	display: flex;
	grid-gap: 10px 0px;
	gap: 10px 0px;
	align-items: center;
	padding-left: 0px;
}

.price-container--ourproduct {
	align-items: center;
	grid-gap: 0px 7px;
	gap: 0px 7px;
}

.decorate-3 {
	position: absolute;
	width: 500px;
	height: 500px;
	background: linear-gradient(
		360deg,
		rgba(211, 161, 126, 0) -21.17%,
		#005afc 60.63%
	);
	opacity: 0.23;
	-webkit-filter: blur(134.5px);
	        filter: blur(134.5px);
	left: -35%;
	top: -25%;
	z-index: 1;
}

.decorate-4 {
	position: absolute;
	width: 500px;
	height: 500px;
	background: linear-gradient(
		360deg,
		rgba(211, 161, 126, 0) -21.17%,
		#005afc 60.63%
	);
	opacity: 0.23;
	-webkit-filter: blur(134.5px);
	        filter: blur(134.5px);
	right: -65%;
	bottom: -25%;
	z-index: 1;
}

.category-container input {
	display: none;
}

.category-container .ourproduct-nav-tab {
	position: absolute;
	height: 100%;
	width: 20%;
	left: 0;
	bottom: -3px;
	z-index: 0;
	transition: 0.6s ease-in-out;
    border-bottom: 2px solid;
}


.category-container #ourproduct-hot:checked ~ .ourproduct-nav-tab {
    left: -1%;
    width: 12%;
}
.category-container #ourproduct-sale:checked ~ .ourproduct-nav-tab {
    left: 26%;
    width: 22%;
}
.category-container #ourproduct-new:checked ~ .ourproduct-nav-tab {
    left: 63%;
    width: 37%;
}
.discount {
    position: relative;
    background: linear-gradient(360deg, #002c3e -21.17%, #6fc7e1 115.88%);
    margin-top: 118px;
}

.discount-content-container {
    flex-direction: column;
    align-items: flex-start;
    grid-gap: 10px 0px;
    gap: 10px 0px;
    margin-left: 85px;
}

img[alt="discount-img"] {
    width: 386px;
    height: 460px;
    margin-top: 44px;
    margin-right: 111px;
    z-index: 100;
}
.discount-heading {
    font-family: 'Frank Ruhl Libre';
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 62px;
    text-transform: capitalize;
    margin: 0 0 0 0;
}

.discount-desc {
    font-weight: 400;
    font-size: 18px;
    line-height: 180%;
    text-transform: capitalize;
    margin: 0 0 19px 0;
}

.date-container {
    grid-gap: 0 18px;
    gap: 0 18px;
    margin: 0 0 32px 0;
}

.time-container {
    flex-direction: column;
    justify-content: center;
    grid-gap: 3px 0px;
    gap: 3px 0px;
    width: 100px;
    height: 100px;
    background-color: white;
}

.discount-number {
    font-weight: 700;
    font-size: 42px;
    line-height: 50px;
    text-transform: capitalize;
    margin: 0 0 0 0;
}

.discount-text {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-transform: capitalize;
    margin: 0 0 0 0;
}

.discount-button {
    background-color: var(--text-color);
    color: white;
    padding: 16px 61px;
}

.rectancle-3 {
    position: absolute;
    width: 379px;
    height: 386px;
    top: 0px;
    right: 0px;
    background: linear-gradient(360deg, #78bcc4 -21.17%, #FFFFFF 115.88%);
    opacity: 0.6;
    z-index: 2;
}


.vecto-6 {
    position: absolute;
    width: 133px;
    height: 107px;
    bottom: 18%;
    right: 1%;
}
.footer {
    background-color: var(--heavyblue-color);
    height: auto;
    margin-top: 100px;
}

.footer-container {
    flex-direction: column;
}

.footer-category {
    list-style: none;
    display: flex;
    grid-gap: 0 45px;
    gap: 0 45px;
    margin-top: 100px;
    margin-bottom: 0px;
    padding-left: 0px;
}

.footer-category-item {
    font-weight: 900;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.115em;
    text-transform: uppercase;
    color: white;
    margin: 0 0 0 0;
    padding-left: 0px;
}

.footer-rightlaw {
    font-weight: 400;
    font-size: 14px;
    line-height: 32px;
    text-align: center;
    text-transform: capitalize;
    color: white;
    margin-top: 59px;
    padding-bottom: 38px;
    margin-bottom: 0px;
}
@-webkit-keyframes move {
	0% {
		left: 0px;
		top: -50px;
		background: none;
	}
	100% {
		left: 0px;
		top: 0px;
		background: rgba(0, 0, 0, 0.8);
	}
}


@keyframes move {
	0% {
		left: 0px;
		top: -50px;
		background: none;
	}
	100% {
		left: 0px;
		top: 0px;
		background: rgba(0, 0, 0, 0.8);
	}
}

@-webkit-keyframes change-bg {
	0% {
		background: none;
	}
	100% {
		background: rgba(0, 0, 0, 0.8);
	}
}

@keyframes change-bg {
	0% {
		background: none;
	}
	100% {
		background: rgba(0, 0, 0, 0.8);
	}
}

.uiewr {
	display: flex;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.8);
	-webkit-animation-name: change-bg;
	        animation-name: change-bg;
	-webkit-animation-duration: 0.2s;
	        animation-duration: 0.2s;
}

.map-popup-container {
	width: 100%;
	height: 100%;
	display: flex;
	opacity: 0;
	transition: opacity 0.7s;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 300;
	visibility: visible;
	opacity: 1;
	-webkit-transform: translateY(0);
	        transform: translateY(0);
	-webkit-animation-name: move;
	        animation-name: move;
	-webkit-animation-duration: 0.2s;
	        animation-duration: 0.2s;
	background: rgba(0, 0, 0, 0.8);
}

.mainContnt {
	width: 100%;
	height: 100%;
	display: flex;
	opacity: 0;
	transition: opacity 0.7s;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 300;
	visibility: visible;
	opacity: 1;
	-webkit-transform: translateY(0);
	        transform: translateY(0);
	-webkit-animation-name: move;
	        animation-name: move;
	-webkit-animation-duration: 0.2s;
	        animation-duration: 0.2s;
}

.loading-container {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	position: fixed;
	z-index: 300;
	opacity: 1;
}

.box {
	display: flex;
	width: 50%;
	transition: all 1s;
	margin: auto;
	background: #fff;
}

.box-map {
	position: relative;
	width: 70%;
	height: 500px;
}

.bx1 {
	width: 80%;
	height: 100%;
}

.bx2 {
	padding: 60px 32px 0 32px;
}


.bx4 {
	position: absolute;
	width: 30%;
	height: 90%;
	right: 60px;
	bottom: 30px;
	z-index: 5;
	background-color: white;
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.bx4 h2 {
	font-family: 'Quicksand';
	font-size: 700;
	margin-top: 35px;
}

.bx4 input {
	border-radius: 5px;
	width: 80%;
	height: 25px;
	font-family: 'Quicksand';
	font-style: normal;
    font-size: 15px;
	padding-left: 10px;
	margin-top: 20px;
	border: 1px solid;
}

.bx4 button {
	width: 80%;
	margin-top: 20px;
	height: 30px;
	border-radius: 24px;
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 700;
	border: 1px solid;
	background-color: #262626;
	color: white
}

.boxes {
	display: flex;
}

.uwywa {
	background: var(--heavyblue-color);
	font-size: 18px;
	text-transform: uppercase;
	line-height: 20px;
	color: #fff;
	width: 100%;
	height: 52px;
	padding: 15px;
	margin-bottom: 25px;
	border-radius: 4px;
	width: 150px;
}

.close-box {
	position: relative;
	text-align: right;
	color: #262626;
	font-weight: bold;
	margin-top: 64px;
	margin-bottom: 32px;
}
.box-close {
	position: absolute;
	bottom: 325px;
	right: 0px;
}

.popup-row {
	display: flex;
	justify-content: flex-end;
	grid-gap: 0px 10px;
	gap: 0px 10px;
}

.popup-col {
	display: flex;
	flex-direction: column;
	grid-gap: 12px 0;
	gap: 12px 0;
}

.popup-heading {
	display: flex;
	justify-content: space-around;
}

.uadfgu {
	display: flex;
	justify-content: flex-start;
}

.popup-p {
	font-family: 'Quicksand';
	font-style: normal;
	font-weight: 400;
	font-size: 20px;
	line-height: 25px;
	text-align: center;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2; /* number of lines to show */
	line-clamp: 2;
	-webkit-box-orient: vertical;
}

.popup-fontsize {
	font-size: 20px;
}

img[alt='popup'] {
	width: 100%;
	height: 100%;
}

@-webkit-keyframes message {
	0% {
		top: -50px;
	}
	100% {
		top: 0px;
	}
}

@keyframes message {
	0% {
		top: -50px;
	}
	100% {
		top: 0px;
	}
}

.message {
	position: relative;
	width: auto;
	height: auto;
	border-radius: 10px;
	background-color: white;
	padding: 32px 32px;
	font-size: 24px;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	flex-direction: column;
	grid-gap: 0 20px;
	gap: 0 20px;
	-webkit-animation-name: message;
	        animation-name: message;
	-webkit-animation-duration: 0.2s;
	        animation-duration: 0.2s;
	transition: opacity 0.2s ease-in-out;
}

@-webkit-keyframes check {
	0% {

		width: 600px;
		height: 550px;
		top: -60%;
		opacity: 0;
	}
	100% {
		width: 120px;
		height: 110px;
		top: -20%;
		opacity: 1;
	}
}

@keyframes check {
	0% {

		width: 600px;
		height: 550px;
		top: -60%;
		opacity: 0;
	}
	100% {
		width: 120px;
		height: 110px;
		top: -20%;
		opacity: 1;
	}
}

@-webkit-keyframes hide-check {
	0% {

		opacity: 0;
	}
	100% {
		opacity: 0;
	}
}

@keyframes hide-check {
	0% {

		opacity: 0;
	}
	100% {
		opacity: 0;
	}
}



.message .check {
	width: 120px;
	height: 110px;
	border-radius: 50%;
	background: #71c341;
	position: absolute;
	top: -20%;
	font-size: 3.8rem;
	color: white;
	display: flex;
	justify-content: space-evenly;
	align-items: flex-start;
	padding-top: 10px;
	-webkit-animation-name: check, hide-check;
	        animation-name: check, hide-check;
	-webkit-animation-duration: 0.2s, 0.2s;
	        animation-duration: 0.2s, 0.2s;
	-webkit-animation-delay: 0.2s, 0s;
	        animation-delay: 0.2s, 0s;
	font-family: Source Sans Pro;
}

.message p {
	font-size: 1.1rem;
	margin: 25px 0px;
	padding: 0;
	font-family: Source Sans Pro;
}

.message p:nth-child(2) {
	font-size: 2.3rem;
	margin: 20px 0px 0px 0px;
}
.message #ok-success {
	position: relative;
	color: white;
	border: 0;
	background: #71c341;
	width: 300px;
	height: 50px;
	border-radius: 6px;
	font-size: 1.2rem;
	transition: background 0.2s ease;
	outline: none;
}
.message #ok-success:hover {
	background: #8ecf68;
}
.message #ok-success:active {
	background: #5a9f32;
}

.message #ok-error {
	position: relative;
	color: white;
	border: 0;
	background: #A50203;
	width: 300px;
	height: 50px;
	border-radius: 6px;
	font-size: 1.2rem;
	transition: background 0.2s ease;
	outline: none;
}
.message #ok-error:hover {
	background: #FF9494;
}
.message #ok-error:active {
	opacity: 0.5;
}
.loader {
	border: 16px solid #f3f3f3; /* Light grey */
	border-top: 16px solid #3498db; /* Blue */
	border-radius: 50%;
	width: 120px;
	height: 120px;
	-webkit-animation: spin 2s linear infinite;
	        animation: spin 2s linear infinite;
	-webkit-animation-timing-function: ease-in-out;
	        animation-timing-function: ease-in-out;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
}



@-webkit-keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
		        transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		        transform: rotate(360deg);
	}
}



@keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
		        transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		        transform: rotate(360deg);
	}
}



.login {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    margin: 0 24 0 24;
}

.loginform {
    background: linear-gradient(112.59deg, #2C5FBA 0%, #FFFFFF 68.81%);
}

.loginform-container {
    grid-gap: 0 116px;
    gap: 0 116px;
    align-items: stretch;
}

img[alt="login"] {
    height: 692px;
    max-width: 2000px;
    border-radius: 0px 0px 250px;
}

.fillup {
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    grid-gap: 14px 0;
    gap: 14px 0;
    margin-top: 101px;
}

.heading-login {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    text-align: center;
    text-transform: uppercase;
    margin: 0 0 0 0;
}

.login-input, select {
    display: inline-block;
    align-items: center;
    justify-content: center;
    width: 553px;
    height: 50px;
    padding: 12px 94px 12px 0;
    border: 1px solid black;
    border-radius: 24px;
    box-sizing: border-box;
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 25px;
    padding-left: 87px;
    padding-top: 12px;
    padding-bottom: 12px;
    margin-top: 22px;
  }

.bt_login {
    width: 553px;
    height: 50px;
    background: #000000;
    border-radius: 24px;
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
    color: white;
    padding-top: 12px;
    padding-bottom: 12px;
    margin-top: 22px;
    margin-bottom: 14px;
}

.forget-content {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    cursor: pointer;
    color: black;
}

.forget-content:hover {
    text-decoration: underline;
}

.or {
    grid-gap: 0 15px;
    gap: 0 15px;
    margin-top: 20px;
}

.horizontal-line {
    width: 206px;
    border: 0.5px solid black;
    background-color: black;
}

.or-content {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
    margin: 0 0 0 0;
}

.login-logo-container {
    grid-gap: 0 76px;
    gap: 0 76px;
    justify-content: center;
}

img[alt="logo"] {
    width: 40px;
    height: 36px;
}

.signup {
    grid-gap: 0 5px;
    gap: 0 5px;
    justify-content: center;
}

.signup-content {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
}

.--heavy:hover {
    text-decoration: underline;
}

.--heavy {
    font-weight: 700;
}

.login-message {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 50px;
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    color: #A50203;
}

@-webkit-keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
		        transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		        transform: rotate(360deg);
	}
}

@keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
		        transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		        transform: rotate(360deg);
	}
}

.login-loading {
    border: 7px solid #f3f3f3; /* Light grey */
	border-top: 7px solid black; /* Blue */
	border-radius: 50%;
	width: 40px;
	height: 40px;
	-webkit-animation: spin 1s linear infinite;
	        animation: spin 1s linear infinite;
	-webkit-animation-timing-function: ease-in-out;
	        animation-timing-function: ease-in-out;
}

header {
    background-color: var(--heavyblue-color);
}

li {
    list-style: none;
}

.container {
    max-width: 1272px;
}

.header {
    justify-content: flex-end;
    align-items: stretch;
    grid-gap: 0 32px;
    gap: 0 32px;
}

.notify {
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.115em;
    color: #FFFFFF;
}

.header-icon {
    font-size: 20px;
    color: white;
    width: 18px; 
    height: 20px;
}

img[alt="avatar"] {
    width: 22px;
    height: 22px;
    border-radius: 50%;
}

.logo{
    justify-content: flex-start;
    align-items: center;
    background-color: white;
    grid-gap: 0 21px;
    gap: 0 21px;
}

.logo-content {
    font-weight: 900;
    font-size: 30px;
    line-height: 36px;
    letter-spacing: 0.115em;
    text-transform: uppercase;
    margin-top: 22px;
    margin-bottom: 22px;
}

.avatar-option {
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}

img[alt="category-avatar"] {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.category-icon {
    font-size: 20px;
    color: black;
}

.category-content {
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    color: var(--text-color);
    cursor: pointer;
}

.account-chirdren-option {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100px;
    color: grey;
    cursor: pointer;
    padding-left: 11px;
}

.checked {
    color: #ee4d2d;
}

.history-wrapper {
    display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	max-width: 1056px;
	margin: 0 auto;
    grid-gap: 0 50px;
    gap: 0 50px;
}

.history-menu-wrapper {
    width: 25%;
}

.history-avatar-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 24px;
    grid-gap: 0px 8px;
    gap: 0px 8px;
}

.history-avatar-wrapper p {
    font-size: 15px;
}


img[alt='category-avatar'] {
    width: 45px;
    height: 45px;
}

.history-menu-wrapper ul input {
    display: none;
}

.history-menu-wrapper li {
    padding-top: 15px;
    cursor: pointer;
    transition: color 0.1s ease;
}

.history-menu-wrapper label {
    transition: color 0.1s ease;
}

.history-menu-wrapper label:hover{
    color: red;
}

.history-menu-icon {
    padding-right: 8px;
}

.history-menu-wrapper ul ul {
    max-height: 0px;
    opacity: 0;
    overflow: hidden;
    transition: all 0.3s ease;
}


.history-menu-wrapper ul #history-menu-account:checked ~ li.history-menu-account ul,
.history-menu-wrapper ul #history-menu-order:checked ~ li.history-menu-order ul,
.history-menu-wrapper ul #history-menu-notify:checked ~ li.history-menu-notify ul,
.history-menu-wrapper ul #history-menu-logout:checked ~ li.history-menu-logout ul {
    max-height: 200px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    opacity: 1;
    padding-left: 23px;
}

header {
    background-color: var(--heavyblue-color);
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
}

.container {
    max-width: 1056px;
}

.container-padding {
    padding-left: 83.32px;
    padding-right: 83.32px;
}

.header {
    justify-content: flex-end;
    align-items: stretch;
    grid-gap: 0 32px;
    gap: 0 32px;
    background-color: var(--heavyblue-color);
    
}

.header-item {
    grid-gap: 0 5px;
    gap: 0 5px;
}

.notify {
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.115em;
    color: #FFFFFF;
}

.header-icon {
    font-size: 20px;
    color: white;
    width: 18px; 
    height: 20px;
}

img[alt="avatar"] {
    width: 22px;
    height: 22px;
    border-radius: 50%;
}

.logo{
    justify-content: flex-start;
    align-items: center;
    background-color: white;
    grid-gap: 0 21px;
    gap: 0 21px;
}

.logo-content {
    font-weight: 900;
    font-size: 30px;
    line-height: 36px;
    letter-spacing: 0.115em;
    text-transform: uppercase;
    margin-top: 22px;
    margin-bottom: 22px;
}
.logo{
    justify-content: flex-start;
    align-items: center;
    background-color: white;
    grid-gap: 0 21px;
    gap: 0 21px;
    max-width: 1272px;
    top: 22px;
}

.logo-content {
    font-family: 'Lato', sans-serif;
    font-weight: 900;
    font-size: 30px;
    line-height: 36px;
    letter-spacing: 0.115em;
    text-transform: uppercase;
    margin-top: 22px;
    margin-bottom: 22px;
}

.location-content {
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 0.115em;
}

.fixed {
    position: fixed;
    width: 100%;
    z-index: 1000;
}
.document {
    height: 500px;
    background-color: white;
    flex-direction: column;
    padding: 20px 25px;
    justify-content: flex-start;
    margin-bottom: 100px;

}

.document-heading {
    font-size: 1.125rem;
    line-height: 1.5rem;
    text-transform: capitalize;
}

.document-desc {
    color: #555;
    font-size: .875rem;
    line-height: 1.0625rem;
    padding-bottom: 25px;
    border-bottom: 0.0625rem solid #efefef;
    margin-top: 0.1875rem;
}

.document-form {
    padding: 1.875rem;
}

.dghdd9 {
    width: 602px;
    margin-bottom: 30px;
}

.h4eiAQ {
    align-items: center;
    display: flex;
    justify-content: flex-end;
}

.tBgRZR {
    width: 20%;
    text-align: right;
    color: rgba(85,85,85,.8);
    overflow: hidden;
}

.gVdPk{
    width: 80%;
    box-sizing: border-box;
    padding-left: 20px;
}

.Z1Wx1m {
    font-size: .875rem;
    color: #333;
}

.ovqcxY {
    align-items: center;
    display: flex;
    box-sizing: border-box;
    width: 100%;
    height: 40px;
    overflow: hidden;
    border: 1px solid rgba(0,0,0,.14);
    border-radius: 2px;
    box-shadow: inset 0 2px 0 rgb(0 0 0 / 2%);
}

.y-NK4C {
    background: none;
    outline: none;
    flex: 1 1;
    flex-shrink: 0;
    -webkit-filter: none;
            filter: none;
    border: 0 !important;
    line-height: normal !important;
    padding: 12px !important;
    margin-top: 0px !important;
    border: 1px solid rgba(0,0,0,.14);
    font-family: 'Lato', sans-serif !important;
    font-size: .875rem !important;
    color: #333 !important;
}
.wrapper{
  height: 60px;
  width: 100% ;
  background: #fff;
  line-height: 60px;
  border-radius: 10px;
  box-shadow: 0 5px 10px rgba(0,0,0,0.25);
}
.wrapper nav{
  position: relative;
  display: flex;
}
.wrapper nav label{
  flex: 1 1;
  width: 100%;
  z-index: 1;
  cursor: pointer;
  display: flex;
  justify-content: space-evenly;
}
.wrapper nav label p{
  position: relative;
  z-index: -1;
  color: #1d1f20;
  font-size: 15px;
  font-weight: 500;
  text-decoration: none;
  transition: color 0.6s ease;
  font-family: 'Poppins', sans-serif;
}
.wrapper nav #history-all:checked ~ label.history-all p,
.wrapper nav #history-cart:checked ~ label.history-cart p,
.wrapper nav #history-delivering:checked ~ label.history-delivering p,
.wrapper nav #history-delivered:checked ~ label.history-delivered p,
.wrapper nav #history-cancelled:checked ~ label.history-cancelled p{
  color: #fff;
}

.wrapper nav .tab{
  position: absolute;
  height: 100%;
  width: 20%;
  left: 0;
  bottom: 0;
  z-index: 0;
  border-radius: 10px;
  background: linear-gradient(45deg, #05abe0 0%,#001e54 100%);
  transition: 0.6s ease-in-out;
}
.wrapper nav #history-cart:checked ~ .tab{
  left: 20%;
}
.wrapper nav #history-delivering:checked ~ .tab{
  left: 40%;
}
.wrapper nav #history-delivered:checked ~ .tab{
  left: 60%;
}
.wrapper nav #history-cancelled:checked ~ .tab{
  left: 80%;
}
.wrapper nav input{
  display: none;
}
.box-shadow {
    box-shadow: 0 5px 10px rgba(0,0,0,0.25);
}

.mall {
    font-weight: 900;
    font-size: 16px;
    line-height: 19px;
    color: white;
    background-color: var(--heavyblue-color);
    padding: 0 4px 0 4px;
}

.shopname {
    font-weight: 900;
    font-size: 16px;
    line-height: 19px;
}

.status-desc {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    text-align: right;
    color: #3EA835;
}

.not-success {
    color: var(--red-color);
}

img[alt="item-img"] {
    width: 82.26px;
    height: 73px;
}

.item-price-history {
    font-weight: 400;
    font-size: 17px;
    line-height: 20px;
    color: var(--red-color);
}

.width {
    width: 82.728%;
}

.horizontal-line {
    width: 100%;
    height: 0.5px;
    background-color: black;
}

.total {
    justify-content: flex-end;
    padding-top: 32px;
    grid-gap: 0 107px;
    gap: 0 107px;
}

.total-content {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
}

.total-amount {
    font-weight: 400;
    font-size: 25px;
    line-height: 30px;
    color: #C20A0A;
}

.item-button {
    justify-content: end;
    padding: 29px 0px 17px 0;
    grid-gap: 0 33px;
    gap: 0 33px;
}

.again {
    display: flex;
    align-items: center;
    text-align: center;
    padding: 13px 45px;
    line-height: 1;
    font-size: 20px;
    font-weight: 700;
    color: white;
    background-color: var(--heavyblue-color);
    cursor: pointer;
}

.viewshop {
    display: flex;
    align-items: center;
    text-align: center;
    padding: 13px 45px;
    line-height: 1;
    font-size: 20px;
    font-weight: 700;
    background-color: var(--gray-color);
    cursor: pointer;
}

.iuyhb {
    overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2; /* number of lines to show */
	line-clamp: 2;
	-webkit-box-orient: vertical;
}
.not-item-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    width: 100%;
    height: 500px;
    grid-gap: 16px 0px;
    gap: 16px 0px;
    border-radius: 10px;
    box-shadow: 0 5px 10px rgba(0,0,0,0.25);
}

img[alt="not-have-item"] {
    width: 100px;
    height: 100px;
}

.not-item-desc {
    font-family: sans-serif;
    font-weight: 400;
    font-size: 15px;
}
.location {
    position: relative;
    align-items: flex-start;
    background-color: white;
    padding-top: 64px;
    padding-bottom: 32px;
}

.username-number {
    font-weight: 900;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.115em;
}

.address {
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.115em;
    width: 61%;
}

.change {
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    text-align: right;
    letter-spacing: 0.115em;
    color: #2361FF;
}
.change:hover {
    cursor: pointer;
    text-decoration: underline;
}

.address-heading {
    position: absolute;
    grid-gap: 6.02px;
    gap: 6.02px;
    top: 20%
}

.adrress-heading-content {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.115em;
    color: var(--heavyblue-color);
}

.map-container {
    position: fixed;
    width: 100%;
    height: 1500px;
    top: 0;
    left: 0;
}
.idItem {
    font-weight: 400;
    font-size: 17px;
    line-height: 20px;
    text-align: center;
    color: gray;
}
.cart-item {
	background-color: white;
	flex-direction: column;
	align-items: stretch;
	margin-bottom: 26px;
	position: relative;
	z-index: 1;
}

.id-item {
	font-weight: 400;
	font-size: 17px;
	line-height: 20px;
	text-align: center;
	color: gray;
}

.name {
	font-weight: 400;
	font-size: 17px;
	line-height: 20px;
	text-transform: capitalize;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2; /* number of lines to show */
	line-clamp: 2;
	-webkit-box-orient: vertical;
}

.option {
	font-weight: 400;
	font-size: 17px;
	line-height: 20px;
	text-align: center;
	text-transform: capitalize;
	color: grey;
	width: 24.407%;
}

.quatity {
	width: 12.203%;
	color: black;
	display: flex;
	justify-content: space-around;
	border: 1px solid rgba(0, 0, 0, 0.09);
}

.quatity-item {
	display: flex;
	justify-content: space-around;
	align-items: center;
	font-size: 20px;
	padding: 2px 0 2px 0;
}

.shop {
	position: absolute;
	grid-gap: 0 11px;
	gap: 0 11px;
	top: 20px;
}

.mall {
	font-weight: 900;
	font-size: 16px;
	line-height: 19px;
	color: white;
	background-color: var(--heavyblue-color);
	padding: 0 4px 0 4px;
}

.shopname {
	font-weight: 900;
	font-size: 16px;
	line-height: 19px;
}

.shopvoucher {
	font-weight: 400;
	font-size: 17px;
	line-height: 20px;
	text-align: center;
}

.item {
	display: flex;
	flex-direction: column;
	background-color: white;
	align-items: center;
	justify-content: space-between;
	margin: 0 auto;
}

.choosevoucher {
	font-weight: 400;
	font-size: 17px;
	line-height: 20px;
	text-align: right;
	color: var(--bluetext-color);
}

.voucher {
	justify-content: flex-end;
	grid-gap: 0 174px;
	gap: 0 174px;
	border: 1px dashed #34251f;
}

.shipment {
	justify-content: flex-end;
	border-bottom: 1px dashed #34251f;
	border-right: 1px dashed #34251f;
	border-left: 1px dashed #34251f;
	background-color: var(--lighblue-color);
	position: relative;
}

.shipment-company {
	font-weight: 400;
	font-size: 17px;
	line-height: 20px;
	width: 35.185%;
	display: flex;
	justify-content: space-around;
}

.shipment-desc {
	font-weight: 400;
	font-size: 17px;
	line-height: 20px;
}

.shipment-change {
	font-weight: 400;
	font-size: 17px;
	line-height: 20px;
	cursor: pointer;
	color: var(--bluetext-color);
	width: 15.556%;
	white-space: nowrap;
	font-display: flex;
	justify-content: space-around;
}

.shipment-price {
	font-weight: 400;
	font-size: 17px;
	line-height: 20px;
	text-align: right;
	width: 24.444%;
}

.shipment-time {
	font-weight: 400;
	font-size: 15px;
	line-height: 18px;
	color: grey;
	position: absolute;
	left: 0;
	white-space: nowrap;
	top: 150%;
}

.item-price-cart {
	justify-content: flex-end;
	border-bottom: 1px dashed #34251f;
	border-right: 1px dashed #34251f;
	border-left: 1px dashed #34251f;
	background-color: var(--lighblue-color);
	grid-gap: 0 24px;
	gap: 0 24px;
	padding-top: 14px;
	padding-bottom: 14px;
}

.itemprice-heading {
	font-weight: 400;
	font-size: 17px;
	line-height: 20px;
	text-align: center;
	color: grey;
}

.itemprice-amount {
	font-weight: 400;
	font-size: 22px;
	line-height: 26px;
	text-align: right;
	color: #c20a0a;
}

.purchase-method {
    background-color: white; 
    flex-direction: column; 
    align-items: stretch;
    border: 1px dashed #34251F;
    padding: 15px 0px;
    /* position: -webkit-sticky;
    position: sticky;
    bottom: 0px; */

}

.method-heading {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
}

.method-content {
    font-weight: 400;
    font-size: 17px;
    line-height: 20px;
}

.method-change {
    font-weight: 400;
    font-size: 17px;
    line-height: 20px;
    color: var(--bluetext-color);
}

.order {
    background-color: var(--heavyblue-color);
    color: white;
    justify-content: space-around;
    display: flex;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    width: 216px;
    height: 45px;
    margin-right: 25px;
}
.cartpage-category-container {
    background-color: white;
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0 45px;
    margin: 20px 0;
    
}

.cartpage-category-container div {
    font-size: 14px;
    color: #888;
    text-align: center;
}


.cart-page-item {
    width: 100%;
    height: auto;
    background-color: white;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.cart-page-item-shop-container {
    width: 100%;
    height: 30px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    grid-gap: 0 10px;
    gap: 0 10px;
    padding: 25px 45px;
    margin: 25px 0px;
    margin-top: 0;
    border-bottom: 1px solid rgba(0,0,0,.09);
}

.cart-page-item-shop-container div {
    font-weight: 900;
	font-size: 16px;
	line-height: 19px;
	color: white;
	background-color: var(--heavyblue-color);
	padding: 0 4px;
}

.cart-page-item-shop-container p {
    font-weight: 900;
	font-size: 16px;
	line-height: 19px;
}

.cart-page-item-infor-container {
    height: auto;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 15px 25px; 
    border: 1px solid rgba(0,0,0,.09);
}

.cart-page-item-infor-container img {
    width: 80px;
    height: 80px;
}


.cart-page-item-infor-container div h2 {
    font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	text-transform: capitalize;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2; /* number of lines to show */
	-webkit-box-orient: vertical;
    width: 100%;
    padding: 5px 5px;
    font-family: 'Quicksand';
}

.cart-page-item-infor-container div p {
    padding: 0 5px;
}

.flex-center {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.flex {
    display: flex;
}

.flex-start {
    display: flex;
    justify-content: flex-start;
}

.flex-end {
    display: flex;
    justify-content: flex-end;
}

.flex-center .price {
    font-size: 14px;
    color: rgba(0,0,0,.54);
}

.flex-center .discountprice {
    font-size: 14px;
    font-weight: 500;
    color: var(--red-color)
}

.cart-page-category-select {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60%;
    flex-direction: column;
    padding: 0 5px;
    font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	/* text-align: start; */
	text-transform: capitalize;
	color: grey;
}

.cart-page-category-select p button {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 5px solid rgba(0,0,0,.54);
    border-bottom: 0;
    margin-left: 2px;
}

.cart-page-shipping-container {
    width: 100%;
    margin-top: 25px;
    padding: 20px 45px;
    height: auto;
    border-top: 1px solid rgba(0,0,0,.09);
    display: flex; 
    grid-gap: 0 10px; 
    gap: 0 10px;
}

.cart-page-shipping-container p {
    font-weight: 500;
    font-size: 14px;
}

.cart-page-shipping-container button {
    color: #05a;
    cursor: pointer;
    border: none;
    background-color: white;
    position: relative;

}

.cart-page-shipping-popup {
    position: absolute;
    top: 30px;
    right: -190px;
    z-index: 10;
    display: block;
    -webkit-animation: all 0s;
            animation: all 0s;
    -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s;
    background-color: white;
    border-radius: 10px;
    background-color: var(--gray-color);
    display: none;
    border: 1px solid;
}

.cart-page-shipping-container button:hover > div {
    display: block;
}

.cart-page-shipping-popup-container {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 30px 20px;
    width: 500px;
    height: auto;
    grid-gap: 10px 0;
    gap: 10px 0;
    position: relative;
}

.cart-page-shipping-popup-container h4 {
    font-weight: 500;
    font-size: 20px;
    color: var(--text-color);
}

.cart-page-shipping-popup-category {
    display: flex;
    align-items: flex-start;
    width: 100%;
}

.text-color {
    color: var(--text-color) !important;
}

.white-text-color {
    color: var(--white-color) !important;
}

.cart-page-shipping-popup-container-decoration {
    position: absolute;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 0;
    border-bottom: 10px solid var(--gray-color);
    margin-left: 2px;
    top: -9px;
    left: 250px;
}

.cart-page-total-container {
    /* position: sticky; */
    bottom: 0;
    width: 100%;
    height: 125px;
    border: 1px dashed;
    flex-direction: column;
    background-color: white;

}

.cart-page-total-voucher {
    width: 100%;
    height: 100%;
    border-bottom: 1px dashed;
    padding: 2px 75px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}


.cart-page-total-voucher div div button {
    border: none;
    background-color: white;
    color: var(--bluetext-color);
    font-size: 14px;
}


.cart-page-total-confirm {
    width: 100%;
    height: 100%;
    padding: 2px 75px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.cart-page-total-confirm div div p {
    text-align: center;
}

.cart-page-total-confirm div div button {
    background-color: var(--heavyblue-color);
    color: white;
    padding: 13px 45px;
    justify-content: flex-end;
}

.red-color {
    color: var(--red-color);
}

.cart-page-delete-popup-container {
    width: 400px;
    background-color: white;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    border-radius: 10px;
}

.cart-page-delete-popup-container h3 {
    padding: 30px 15px;
    font-family: 'Quicksand';
}   

.cart-page-delete-popup-container p {
    padding: 30px 15px;
    padding-top: 0;
    text-transform: capitalize;
}

.cart-page-delete-popup-container div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 30px 15px;
    padding-top: 0;
    width: 100%;
    grid-gap: 0 10px;
    gap: 0 10px;
}

.cart-page-delete-popup-container div button {
    border-radius: 5px;
    width: 50%;
    padding: 10px 0;
    cursor: pointer
}
