.cartpage-category-container {
    background-color: white;
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0 45px;
    margin: 20px 0;
    
}

.cartpage-category-container div {
    font-size: 14px;
    color: #888;
    text-align: center;
}


.cart-page-item {
    width: 100%;
    height: auto;
    background-color: white;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.cart-page-item-shop-container {
    width: 100%;
    height: 30px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0 10px;
    padding: 25px 45px;
    margin: 25px 0px;
    margin-top: 0;
    border-bottom: 1px solid rgba(0,0,0,.09);
}

.cart-page-item-shop-container div {
    font-weight: 900;
	font-size: 16px;
	line-height: 19px;
	color: white;
	background-color: var(--heavyblue-color);
	padding: 0 4px;
}

.cart-page-item-shop-container p {
    font-weight: 900;
	font-size: 16px;
	line-height: 19px;
}

.cart-page-item-infor-container {
    height: auto;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 15px 25px; 
    border: 1px solid rgba(0,0,0,.09);
}

.cart-page-item-infor-container img {
    width: 80px;
    height: 80px;
}


.cart-page-item-infor-container div h2 {
    font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	text-transform: capitalize;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2; /* number of lines to show */
	-webkit-box-orient: vertical;
    width: 100%;
    padding: 5px 5px;
    font-family: 'Quicksand';
}

.cart-page-item-infor-container div p {
    padding: 0 5px;
}

.flex-center {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.flex {
    display: flex;
}

.flex-start {
    display: flex;
    justify-content: flex-start;
}

.flex-end {
    display: flex;
    justify-content: flex-end;
}

.flex-center .price {
    font-size: 14px;
    color: rgba(0,0,0,.54);
}

.flex-center .discountprice {
    font-size: 14px;
    font-weight: 500;
    color: var(--red-color)
}

.cart-page-category-select {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60%;
    flex-direction: column;
    padding: 0 5px;
    font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	/* text-align: start; */
	text-transform: capitalize;
	color: grey;
}

.cart-page-category-select p button {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 5px solid rgba(0,0,0,.54);
    border-bottom: 0;
    margin-left: 2px;
}

.cart-page-shipping-container {
    width: 100%;
    margin-top: 25px;
    padding: 20px 45px;
    height: auto;
    border-top: 1px solid rgba(0,0,0,.09);
    display: flex; 
    gap: 0 10px;
}

.cart-page-shipping-container p {
    font-weight: 500;
    font-size: 14px;
}

.cart-page-shipping-container button {
    color: #05a;
    cursor: pointer;
    border: none;
    background-color: white;
    position: relative;

}

.cart-page-shipping-popup {
    position: absolute;
    top: 30px;
    right: -190px;
    z-index: 10;
    display: block;
    animation: all 0s;
    animation-delay: 0.2s;
    background-color: white;
    border-radius: 10px;
    background-color: var(--gray-color);
    display: none;
    border: 1px solid;
}

.cart-page-shipping-container button:hover > div {
    display: block;
}

.cart-page-shipping-popup-container {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 30px 20px;
    width: 500px;
    height: auto;
    gap: 10px 0;
    position: relative;
}

.cart-page-shipping-popup-container h4 {
    font-weight: 500;
    font-size: 20px;
    color: var(--text-color);
}

.cart-page-shipping-popup-category {
    display: flex;
    align-items: flex-start;
    width: 100%;
}

.text-color {
    color: var(--text-color) !important;
}

.white-text-color {
    color: var(--white-color) !important;
}

.cart-page-shipping-popup-container-decoration {
    position: absolute;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 0;
    border-bottom: 10px solid var(--gray-color);
    margin-left: 2px;
    top: -9px;
    left: 250px;
}

.cart-page-total-container {
    /* position: sticky; */
    bottom: 0;
    width: 100%;
    height: 125px;
    border: 1px dashed;
    flex-direction: column;
    background-color: white;

}

.cart-page-total-voucher {
    width: 100%;
    height: 100%;
    border-bottom: 1px dashed;
    padding: 2px 75px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}


.cart-page-total-voucher div div button {
    border: none;
    background-color: white;
    color: var(--bluetext-color);
    font-size: 14px;
}


.cart-page-total-confirm {
    width: 100%;
    height: 100%;
    padding: 2px 75px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.cart-page-total-confirm div div p {
    text-align: center;
}

.cart-page-total-confirm div div button {
    background-color: var(--heavyblue-color);
    color: white;
    padding: 13px 45px;
    justify-content: flex-end;
}

.red-color {
    color: var(--red-color);
}

.cart-page-delete-popup-container {
    width: 400px;
    background-color: white;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    border-radius: 10px;
}

.cart-page-delete-popup-container h3 {
    padding: 30px 15px;
    font-family: 'Quicksand';
}   

.cart-page-delete-popup-container p {
    padding: 30px 15px;
    padding-top: 0;
    text-transform: capitalize;
}

.cart-page-delete-popup-container div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 30px 15px;
    padding-top: 0;
    width: 100%;
    gap: 0 10px;
}

.cart-page-delete-popup-container div button {
    border-radius: 5px;
    width: 50%;
    padding: 10px 0;
    cursor: pointer
}