.not-item-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    width: 100%;
    height: 500px;
    gap: 16px 0px;
    border-radius: 10px;
    box-shadow: 0 5px 10px rgba(0,0,0,0.25);
}

img[alt="not-have-item"] {
    width: 100px;
    height: 100px;
}

.not-item-desc {
    font-family: sans-serif;
    font-weight: 400;
    font-size: 15px;
}