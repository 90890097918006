
header {
    background-color: var(--heavyblue-color);
}

li {
    list-style: none;
}

.container {
    max-width: 1272px;
}

.header {
    justify-content: flex-end;
    align-items: stretch;
    gap: 0 32px;
}

.notify {
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.115em;
    color: #FFFFFF;
}

.header-icon {
    font-size: 20px;
    color: white;
    width: 18px; 
    height: 20px;
}

img[alt="avatar"] {
    width: 22px;
    height: 22px;
    border-radius: 50%;
}

.logo{
    justify-content: flex-start;
    align-items: center;
    background-color: white;
    gap: 0 21px;
}

.logo-content {
    font-weight: 900;
    font-size: 30px;
    line-height: 36px;
    letter-spacing: 0.115em;
    text-transform: uppercase;
    margin-top: 22px;
    margin-bottom: 22px;
}

.avatar-option {
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}

img[alt="category-avatar"] {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.category-icon {
    font-size: 20px;
    color: black;
}

.category-content {
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    color: var(--text-color);
    cursor: pointer;
}

.account-chirdren-option {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100px;
    color: grey;
    cursor: pointer;
    padding-left: 11px;
}

.checked {
    color: #ee4d2d;
}

.history-wrapper {
    display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	max-width: 1056px;
	margin: 0 auto;
    gap: 0 50px;
}

.history-menu-wrapper {
    width: 25%;
}

.history-avatar-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 24px;
    gap: 0px 8px;
}

.history-avatar-wrapper p {
    font-size: 15px;
}


img[alt='category-avatar'] {
    width: 45px;
    height: 45px;
}

.history-menu-wrapper ul input {
    display: none;
}

.history-menu-wrapper li {
    padding-top: 15px;
    cursor: pointer;
    transition: color 0.1s ease;
}

.history-menu-wrapper label {
    transition: color 0.1s ease;
}

.history-menu-wrapper label:hover{
    color: red;
}

.history-menu-icon {
    padding-right: 8px;
}

.history-menu-wrapper ul ul {
    max-height: 0px;
    opacity: 0;
    overflow: hidden;
    transition: all 0.3s ease;
}


.history-menu-wrapper ul #history-menu-account:checked ~ li.history-menu-account ul,
.history-menu-wrapper ul #history-menu-order:checked ~ li.history-menu-order ul,
.history-menu-wrapper ul #history-menu-notify:checked ~ li.history-menu-notify ul,
.history-menu-wrapper ul #history-menu-logout:checked ~ li.history-menu-logout ul {
    max-height: 200px;
    height: fit-content;
    opacity: 1;
    padding-left: 23px;
}
