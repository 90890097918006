.footer {
    background-color: var(--heavyblue-color);
    height: auto;
    margin-top: 100px;
}

.footer-container {
    flex-direction: column;
}

.footer-category {
    list-style: none;
    display: flex;
    gap: 0 45px;
    margin-top: 100px;
    margin-bottom: 0px;
    padding-left: 0px;
}

.footer-category-item {
    font-weight: 900;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.115em;
    text-transform: uppercase;
    color: white;
    margin: 0 0 0 0;
    padding-left: 0px;
}

.footer-rightlaw {
    font-weight: 400;
    font-size: 14px;
    line-height: 32px;
    text-align: center;
    text-transform: capitalize;
    color: white;
    margin-top: 59px;
    padding-bottom: 38px;
    margin-bottom: 0px;
}