.cart-item {
	background-color: white;
	flex-direction: column;
	align-items: stretch;
	margin-bottom: 26px;
	position: relative;
	z-index: 1;
}

.id-item {
	font-weight: 400;
	font-size: 17px;
	line-height: 20px;
	text-align: center;
	color: gray;
}

.name {
	font-weight: 400;
	font-size: 17px;
	line-height: 20px;
	text-transform: capitalize;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2; /* number of lines to show */
	line-clamp: 2;
	-webkit-box-orient: vertical;
}

.option {
	font-weight: 400;
	font-size: 17px;
	line-height: 20px;
	text-align: center;
	text-transform: capitalize;
	color: grey;
	width: 24.407%;
}

.quatity {
	width: 12.203%;
	color: black;
	display: flex;
	justify-content: space-around;
	border: 1px solid rgba(0, 0, 0, 0.09);
}

.quatity-item {
	display: flex;
	justify-content: space-around;
	align-items: center;
	font-size: 20px;
	padding: 2px 0 2px 0;
}

.shop {
	position: absolute;
	gap: 0 11px;
	top: 20px;
}

.mall {
	font-weight: 900;
	font-size: 16px;
	line-height: 19px;
	color: white;
	background-color: var(--heavyblue-color);
	padding: 0 4px 0 4px;
}

.shopname {
	font-weight: 900;
	font-size: 16px;
	line-height: 19px;
}

.shopvoucher {
	font-weight: 400;
	font-size: 17px;
	line-height: 20px;
	text-align: center;
}

.item {
	display: flex;
	flex-direction: column;
	background-color: white;
	align-items: center;
	justify-content: space-between;
	margin: 0 auto;
}

.choosevoucher {
	font-weight: 400;
	font-size: 17px;
	line-height: 20px;
	text-align: right;
	color: var(--bluetext-color);
}

.voucher {
	justify-content: flex-end;
	gap: 0 174px;
	border: 1px dashed #34251f;
}

.shipment {
	justify-content: flex-end;
	border-bottom: 1px dashed #34251f;
	border-right: 1px dashed #34251f;
	border-left: 1px dashed #34251f;
	background-color: var(--lighblue-color);
	position: relative;
}

.shipment-company {
	font-weight: 400;
	font-size: 17px;
	line-height: 20px;
	width: 35.185%;
	display: flex;
	justify-content: space-around;
}

.shipment-desc {
	font-weight: 400;
	font-size: 17px;
	line-height: 20px;
}

.shipment-change {
	font-weight: 400;
	font-size: 17px;
	line-height: 20px;
	cursor: pointer;
	color: var(--bluetext-color);
	width: 15.556%;
	white-space: nowrap;
	font-display: flex;
	justify-content: space-around;
}

.shipment-price {
	font-weight: 400;
	font-size: 17px;
	line-height: 20px;
	text-align: right;
	width: 24.444%;
}

.shipment-time {
	font-weight: 400;
	font-size: 15px;
	line-height: 18px;
	color: grey;
	position: absolute;
	left: 0;
	white-space: nowrap;
	top: 150%;
}

.item-price-cart {
	justify-content: flex-end;
	border-bottom: 1px dashed #34251f;
	border-right: 1px dashed #34251f;
	border-left: 1px dashed #34251f;
	background-color: var(--lighblue-color);
	gap: 0 24px;
	padding-top: 14px;
	padding-bottom: 14px;
}

.itemprice-heading {
	font-weight: 400;
	font-size: 17px;
	line-height: 20px;
	text-align: center;
	color: grey;
}

.itemprice-amount {
	font-weight: 400;
	font-size: 22px;
	line-height: 26px;
	text-align: right;
	color: #c20a0a;
}
