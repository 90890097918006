@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&family=Quicksand:wght@400;700&display=swap');

:root {
	--white-color: #fff;
	--black-color: #000;
	--text-color: #1d1f20;
	--lighblue-color: #e0f6f8;
	--heavyblue-color: #001e54;
	--gray-color: #f3f3f3;
	--bluetext-color: #2361ff;
	--red-color: #C20A0A;
	--brown-color: #34251F;
	
}

* {
	box-sizing: inherit; /*Kế thừa*/
	margin: 0 0;
	padding: 0 0;
	font-family: 'Lato', sans-serif;
}

html {
	font-family: 'Lato', sans-serif;
	font-style: normal;
	color: var(--text-color);
	box-sizing: border-box;
}

body {
	margin: 0;
	overflow-y: scroll;
	overflow-x: hidden;
}

.App {
	max-width: 100%;
	margin: 0 auto;
}
.container-item {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

a {
	text-decoration: none;
}

img {
	display: block;
	max-width: 100%;
}

.button {
	display: inline-block;
	align-items: center;
	font-family: 'Lato', sans-serif;
	line-height: 1;
	font-size: 16px;
	cursor: pointer;
}

.line {
	border-left: 0.5px solid black;
	height: 56px;
	left: 50%;
	margin-left: -3px;
	top: 0;
}

.container {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.container-1139 {
	display: flex;
	justify-content: space-between;
	align-items: center;
	max-width: 1139px;
	margin: 0 auto;
}

.container-1056 {
	display: flex;
	justify-content: space-between;
	align-items: center;
	max-width: 1056px;
	margin: 0 auto;
}
