@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400);


@keyframes move {
	0% {
		left: 0px;
		top: -50px;
		background: none;
	}
	100% {
		left: 0px;
		top: 0px;
		background: rgba(0, 0, 0, 0.8);
	}
}

@keyframes change-bg {
	0% {
		background: none;
	}
	100% {
		background: rgba(0, 0, 0, 0.8);
	}
}

.uiewr {
	display: flex;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.8);
	animation-name: change-bg;
	animation-duration: 0.2s;
}

.map-popup-container {
	width: 100%;
	height: 100%;
	display: flex;
	opacity: 0;
	transition: opacity 0.7s;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 300;
	visibility: visible;
	opacity: 1;
	transform: translateY(0);
	animation-name: move;
	animation-duration: 0.2s;
	background: rgba(0, 0, 0, 0.8);
}

.mainContnt {
	width: 100%;
	height: 100%;
	display: flex;
	opacity: 0;
	transition: opacity 0.7s;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 300;
	visibility: visible;
	opacity: 1;
	transform: translateY(0);
	animation-name: move;
	animation-duration: 0.2s;
}

.loading-container {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	position: fixed;
	z-index: 300;
	opacity: 1;
}

.box {
	display: flex;
	width: 50%;
	transition: all 1s;
	margin: auto;
	background: #fff;
}

.box-map {
	position: relative;
	width: 70%;
	height: 500px;
}

.bx1 {
	width: 80%;
	height: 100%;
}

.bx2 {
	padding: 60px 32px 0 32px;
}


.bx4 {
	position: absolute;
	width: 30%;
	height: 90%;
	right: 60px;
	bottom: 30px;
	z-index: 5;
	background-color: white;
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.bx4 h2 {
	font-family: 'Quicksand';
	font-size: 700;
	margin-top: 35px;
}

.bx4 input {
	border-radius: 5px;
	width: 80%;
	height: 25px;
	font-family: 'Quicksand';
	font-style: normal;
    font-size: 15px;
	padding-left: 10px;
	margin-top: 20px;
	border: 1px solid;
}

.bx4 button {
	width: 80%;
	margin-top: 20px;
	height: 30px;
	border-radius: 24px;
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 700;
	border: 1px solid;
	background-color: #262626;
	color: white
}

.boxes {
	display: flex;
}

.uwywa {
	background: var(--heavyblue-color);
	font-size: 18px;
	text-transform: uppercase;
	line-height: 20px;
	color: #fff;
	width: 100%;
	height: 52px;
	padding: 15px;
	margin-bottom: 25px;
	border-radius: 4px;
	width: 150px;
}

.close-box {
	position: relative;
	text-align: right;
	color: #262626;
	font-weight: bold;
	margin-top: 64px;
	margin-bottom: 32px;
}
.box-close {
	position: absolute;
	bottom: 325px;
	right: 0px;
}

.popup-row {
	display: flex;
	justify-content: flex-end;
	gap: 0px 10px;
}

.popup-col {
	display: flex;
	flex-direction: column;
	gap: 12px 0;
}

.popup-heading {
	display: flex;
	justify-content: space-around;
}

.uadfgu {
	display: flex;
	justify-content: flex-start;
}

.popup-p {
	font-family: 'Quicksand';
	font-style: normal;
	font-weight: 400;
	font-size: 20px;
	line-height: 25px;
	text-align: center;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2; /* number of lines to show */
	line-clamp: 2;
	-webkit-box-orient: vertical;
}

.popup-fontsize {
	font-size: 20px;
}

img[alt='popup'] {
	width: 100%;
	height: 100%;
}

@keyframes message {
	0% {
		top: -50px;
	}
	100% {
		top: 0px;
	}
}

.message {
	position: relative;
	width: auto;
	height: auto;
	border-radius: 10px;
	background-color: white;
	padding: 32px 32px;
	font-size: 24px;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	flex-direction: column;
	gap: 0 20px;
	animation-name: message;
	animation-duration: 0.2s;
	transition: opacity 0.2s ease-in-out;
}

@keyframes check {
	0% {

		width: 600px;
		height: 550px;
		top: -60%;
		opacity: 0;
	}
	100% {
		width: 120px;
		height: 110px;
		top: -20%;
		opacity: 1;
	}
}

@keyframes hide-check {
	0% {

		opacity: 0;
	}
	100% {
		opacity: 0;
	}
}



.message .check {
	width: 120px;
	height: 110px;
	border-radius: 50%;
	background: #71c341;
	position: absolute;
	top: -20%;
	font-size: 3.8rem;
	color: white;
	display: flex;
	justify-content: space-evenly;
	align-items: flex-start;
	padding-top: 10px;
	animation-name: check, hide-check;
	animation-duration: 0.2s, 0.2s;
	animation-delay: 0.2s, 0s;
	font-family: Source Sans Pro;
}

.message p {
	font-size: 1.1rem;
	margin: 25px 0px;
	padding: 0;
	font-family: Source Sans Pro;
}

.message p:nth-child(2) {
	font-size: 2.3rem;
	margin: 20px 0px 0px 0px;
}
.message #ok-success {
	position: relative;
	color: white;
	border: 0;
	background: #71c341;
	width: 300px;
	height: 50px;
	border-radius: 6px;
	font-size: 1.2rem;
	transition: background 0.2s ease;
	outline: none;
}
.message #ok-success:hover {
	background: #8ecf68;
}
.message #ok-success:active {
	background: #5a9f32;
}

.message #ok-error {
	position: relative;
	color: white;
	border: 0;
	background: #A50203;
	width: 300px;
	height: 50px;
	border-radius: 6px;
	font-size: 1.2rem;
	transition: background 0.2s ease;
	outline: none;
}
.message #ok-error:hover {
	background: #FF9494;
}
.message #ok-error:active {
	opacity: 0.5;
}